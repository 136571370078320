/* eslint no-unused-vars: 0 */
import "vite/modulepreload-polyfill";

import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import Noty from "noty";
import { createApp } from "vue";

import SubscribeFormContainer from "./components/SubscribeForm/SubscribeFormContainer.vue";

Noty.overrideDefaults({
  theme: "bootstrap-v4",
  layout: "topCenter",
  timeout: 2000,
});

// NEWSLETTER should be defined in `subscribe_form.html`
// eslint-disable-next-line
const constructApp = () => {
  return createApp(SubscribeFormContainer, {
    newsletter: NEWSLETTER, // eslint-disable-line no-undef
    extantSubscriber: SUBSCRIBER, // eslint-disable-line no-undef
    subscriberEmail: SUBSCRIBER_EMAIL, // eslint-disable-line no-undef
    variant: VARIANT, // eslint-disable-line no-undef
  });
};

// Fix CSRF.
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const primaryApp = constructApp();

// Enable Sentry.
// Via https://github.com/getsentry/sentry-javascript/issues/2925.
const shouldTrackErrors = NEWSLETTER.should_track_errors; // eslint-disable-line no-undef
if (shouldTrackErrors) {
  Sentry.init({
    app: primaryApp,
    dsn: "https://98d0ca1c1c554806b630fa9caf185b1f@o97520.ingest.sentry.io/6063581",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["buttondown.email", "buttondown.com"],
      }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "Non-Error",
      "Can't find variable: pktAnnotationHighlighter",
      "ChunkLoadError",
    ],
  });
}

primaryApp.mount("#subscribe-form");

primaryApp.config.errorHandler = (err) => {
  Sentry.captureException(err);
};

const secondaryForms = document.getElementsByClassName("subscribe-form");
Array.from(secondaryForms).forEach((el) => {
  constructApp().mount(el);
});

// Handle Substack-imported forms.
const substackForms = document.getElementsByClassName(
  "subscription-widget-subscribe"
);
Array.from(substackForms).forEach((el) => {
  constructApp().mount(el);
});

export default primaryApp;
